import "./WebsitePerformance.scss";

import { Box, Card } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import CustomAreaChartComponent from "../../components/CustomCharts/AreaCharts/CustomAreaCharts";
import CustomBarChartComponent from "../../components/CustomCharts/BarChart/CustomBarChartComponent";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

// Define TypeScript types for better type safety
interface LandingPageData {
  date: {
    data: string;
    mainStats: string;
  };
  firstUserDefaultChannelGroup: string;
}

interface ChartData {
  curDate: string;
  preDate: string;
  curData: {
    Sessions: number;
    Conversions: number;
  };
  preData: {
    "Key events": number;
     Conversions: number;
  };
}


interface BarChartData {
  'FirstUser DefaultChannelGroup' : string;
  curData: {
    Sessions: string,
    'Key events': string
  }
}


const WebsitePerformance = () => {
  const { landingPageData } = useSelector(
    (state: { businessdashboardDetails: { landingPageData: LandingPageData } }) => state.businessdashboardDetails
  );

  const [ga4Date, setGa4Date] = useState<string[]>([]);
  const [ga4Data, setGa4Data] = useState<any[]>([]);

  // Memoize parsed data from landingPageData
  const ga4ChartDateMemo = useMemo(() => {
    if (!landingPageData?.date) return { data: [], keys: [] };
    try {
      const parsedData: ChartData[] = JSON.parse(landingPageData.date.data);
      if (Array.isArray(parsedData)) {
        const chartKeys = parsedData.map((row) => 
          row.curDate ? dayjs(row.curDate).format("YYYY-MM-DD") : ""
        );
        const seriesData = [
          {
            name: "Sessions",
            data: parsedData.map((row) => row.curData["Sessions"] ?? 0),
          },
          {
            name: "Conversions",
            data: parsedData.map((row) => row.curData.Conversions ?? 0),
          },
        ];
        return { data: seriesData, keys: chartKeys };
      }
    } catch (error) {
      console.error("Error parsing JSON in ga4ChartDateMemo:", error);
    }
    return { data: [], keys: [] };
  }, [landingPageData]);

  useEffect(() => {
    if (ga4ChartDateMemo) {
      setGa4Date(ga4ChartDateMemo.keys);
      setGa4Data(ga4ChartDateMemo.data);
    }
  }, [ga4ChartDateMemo]);

  
  const [ga4BarDate, setGa4BarDate] = useState<string[]>([]);
  const [ga4BarData, setGa4BarData] = useState<any[]>([]);
  console.log("WebsitePerformance ~ ga4BarData:", ga4BarData)


  const ga4BarChartDateMemo = useMemo(() => {
    if (!landingPageData?.firstUserDefaultChannelGroup) return { data: [], keys: [] };
  
    try {
      const parsedData: BarChartData[] = JSON.parse(landingPageData.firstUserDefaultChannelGroup);
      console.log("landingPageData parsedata", parsedData);
      
      if (Array.isArray(parsedData)) {
        // Extract channel group keys dynamically
        const chartKeys = parsedData.map((row) => row["FirstUser DefaultChannelGroup"] || "");
  
        // Create series data for Sessions and Key events
        const sessionsSeries = {
          name: "Sessions",
          data: parsedData.map((row) => row.curData?.Sessions ? parseInt(row.curData.Sessions, 10) : 0),
        };
  
        const keyEventsSeries = {
          name: "Key events",
          data: parsedData.map((row) => row.curData?.["Key events"] ? parseInt(row.curData["Key events"], 10) : 0),
        };
        return { data: [sessionsSeries, keyEventsSeries], keys: chartKeys };
      }
    } catch (error) {
      console.error("Error parsing JSON in ga4ChartDateMemo:", error);
    }
  
    return { data: [], keys: [] };
  }, [landingPageData]);
  


  const memoizedGACardData = useMemo(() => {

    if (!landingPageData || !landingPageData?.date?.mainStats) {
      return {};
    }

    try {
      const jsonResponse = JSON.parse(landingPageData?.date?.mainStats);
      return jsonResponse;
    } catch (error) {
      console.error("Error parsing JSON in useMemo:", error);
    } 
    return [];
  }, [landingPageData]);

  console.log("memoizedGACardData", memoizedGACardData);
  

  useEffect(() => {
    if (ga4BarChartDateMemo) {
      setGa4BarDate(ga4BarChartDateMemo.keys);
      setGa4BarData(ga4BarChartDateMemo.data);
    }
  }, [ga4BarChartDateMemo]);


  return (
    <Box>
      {ga4Data.length > 0 && ga4Date.length > 0 && (
        <CustomAreaChartComponent
          key={JSON.stringify(ga4Data)}
          chartType="area"
          colors={[{sessions:"#78909C"}, {conversions:"#007bff"}]}
          categories={ga4Date}
          overViews={memoizedGACardData}
          seriesData={ga4Data}
        />
      )}
      <Card>
        {ga4BarData.length > 0 && ga4BarData.length > 0 && (
          <CustomBarChartComponent series={ga4BarData} categories={ga4BarDate} />
        )}
      </Card>
    </Box>
  );
};

export default WebsitePerformance;
